import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero";
import Section from "../../components/ui/Section/Section";
import ButtonLink from "../../components/ui/Buttons/ButtonLink";
import VerticalFold from "../../components/ui/VerticalFold/VerticalFold";
import ButtonLinkCard from "../../components/ui/Buttons/ButtonLinkCard";
import Questions from "../../components/blocks/Questions";
import ImageText from "../../components/blocks/ImageText";

export default ({ data }) => {

    const druckverfahrenTypes: Array<any> = [
        {
            key: "druckverfahren",
            title: "Druck&shy;verfahren",
            description: "Auftragen von Tinte über einen Druckkopf ist nicht ganz so trivial. Kleine Unterschiede ermöglichen andere Verwendungszwecke. Erfahren Sie hier die Unterschiede!",
            items: data.allDruckverfahrenHJson.edges.filter(item => item.node.type === "druckverfahren"),
        },
        {
            key: "beschriftungsverfahren",
            title: "Beschrift&shy;ungen",
            description: "Diese Verfahren ermöglichen eine Beschriftung ohne Tinte. Die Beschriftungsverfahren sind meist mit Materialumformung oder Materialauftrag verbunden.",
            items: data.allDruckverfahrenHJson.edges.filter(item => item.node.type === "beschriftungsverfahren"),
        },
    ] 
   
    return (
        <Layout headerColor={"light"} hasWave={false}  crumbs={[{ label: "Home", url: "" }, { label: "Leistungen", url: "leistungen" }, { label: "Druckverfahren", url: "druckverfahren"}]}>
            <Hero 
                title="Druck&shy;verfahren" 
                subtitle="Etablierte und geprüfte Druckverfahren bringen Ihre Nachricht auf das Material. Unterschiedliche Verfahren bieten unterschiedliche Eigenschaften. Erfahren Sie hier was das jeweilige Druckverfahren stark macht." 
                hasWaveBottom={["mobile", "tablet"]}
                size="is-medium"
            />
            { druckverfahrenTypes.map((item, sectionKey) => (
            <Section key={sectionKey}  hasTitle={true}>
                <div className="columns">
                    <div className="column is-one-third">
                        <h2  className="title is-2" dangerouslySetInnerHTML={{__html: item.title}}></h2>
                        <p dangerouslySetInnerHTML={{__html: item.description}}>
                            
                        </p>
                    </div>
                    <VerticalFold textCollapsed="weitere Verfahren" textOpen="weniger Verfahren" wrapper={(props) => <div className="columns is-multiline">{props.children}</div>}>

                        {item.items.map((document) => (
                            <div className="column is-one-third" key={document.node.url}>
                                <ButtonLinkCard to={`/leistungen/druckverfahren/${document.node.url}`}>
                                    <div className=" is-hidden-mobile">
                                        <h3 className="title is-4">{document.node.name}</h3>
                                        <ButtonLink to={`/leistungen/druckverfahren/${document.node.url}`} variation="minimal">
                                            <span className="is-hidden-desktop-only is-hidden-tablet-only">Mehr Erfahren</span>
                                            <span className="is-hidden-widescreen">Mehr</span>
                                        </ButtonLink>
                                    </div>
                                    <div className="content is-hidden-tablet is-hidden-desktop">
                                        <ButtonLink to={`/leistungen/druckverfahren/${document.node.url}`} variation="minimal" className="is-block"><h3 style={{marginBottom: 0}}>{document.node.name}</h3></ButtonLink>
                                    </div>
                                </ButtonLinkCard>
                            </div>
                        ))}
                    </VerticalFold>
                </div>
            </Section>

            ))}

            <Questions />


            <Section size="extraSmall"  hasTitle={true}>
                <ImageText image={{src: data.images.edges[0].node, alt: "Weiterverarbeitung im Detail"}}>
                    <h3>Der letzte Schliff durch Weiterverarbeitung</h3>
                    <p>
                        Der letzte Schritt ist die Weiterverarbeitung. Veredelt wird das Produkt um die Haltbarkeit zu verlängern oder um Befestigungen zu ermöglichen oder um das Produkt durch Sonderfarben ästhetischer zu gestalten. Unsere Informationssammlung über Weiterverarbeitung zeigt Ihnen was möglich ist.
                    </p>
                    <ButtonLink to="/leistungen/weiterverarbeitung">Weiter zur Weiterverarbeitung</ButtonLink>
                </ImageText>
            </Section>

        </Layout>
    )
}

export const pageQuery = graphql`{
  allDruckverfahrenHJson {
    edges {
      node {
        description
        img
        name
        type
        url
      }
    }
  }
  images: allFile(
    filter: {relativePath: {regex: "/leistungen/weiterverarbeitung.jpg/"}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`